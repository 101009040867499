.header {
  position: fixed;
  top: 0;
  right: 0;
  margin-left: 0;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0.46875rem 2.1875rem rgb(59 62 102 / 3%),
    0 0.9375rem 1.40625rem rgb(59 62 102 / 3%),
    0 0.25rem 0.53125rem rgb(59 62 102 / 5%),
    0 0.125rem 0.1875rem rgb(59 62 102 / 3%);
  height: 74px;
  width: 100%;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3063;
  line-height: 2;
}

.panel span {
  margin-left: 10px;
}

.btn {
  border-radius: 5px;
  background-color: transparent;
  border: none;
  transition: all 0.5s ease-out;
  padding: 5px 7px;
  color: #2c3063;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
}

@media (max-width: 767px) {
  .header {
    padding: 0 1rem;
  }
}
