body {
  box-sizing: border-box;
  background-color: #f4f5fd !important;
  overflow-x: hidden;
  padding: 0 !important;
}

a {
  text-decoration: none !important;
}

td {
  vertical-align: middle;
}

small {
  color: gray;
}

button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

input:focus {
  color: #3b3e66 !important;
  background-color: #fff !important;
  border-color: #9297da !important;
  outline: 0;
  box-shadow: 0 0.313rem 0.719rem rgb(122 123 151 / 10%),
    0 0.156rem 0.125rem rgb(122 123 151 / 10%) !important;
}

textarea:focus {
  color: #3b3e66 !important;
  background-color: #fff !important;
  border-color: #9297da !important;
  outline: 0;
  box-shadow: 0 0.313rem 0.719rem rgb(122 123 151 / 10%),
    0 0.156rem 0.125rem rgb(122 123 151 / 10%) !important;
}

.font-size-xs {
  font-size: 12px;
  color: darkgray;
}

/* sort */
table th.sortable {
  cursor: pointer;
}

.sort-up:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 1;
}

.sort-up:after {
  content: '\2193';
  opacity: 0.4;
}

.sort-down:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.sort-down:after {
  content: '\2193';
  opacity: 1;
}

/* Loader */

.center-loader {
  margin-left: auto;
  margin-right: auto;
  padding: 150px 0;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #575fbe;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/*modal-z-index-adjust*/
.app-header {
  z-index: 998;
}

.app-sidebar {
  z-index: 1000;
}

.app-sidebar-overlay {
  z-index: 999;
}

/*login*/
.panel {
  background-color: #070919;
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100vh;
}

.login {
  /* width: 500px; */
  width: 100%;
  max-width: 500px;
  padding: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.login .card {
  padding: 32px;
}

@media screen and (max-width: 767px) {
  .card-box {
    max-width: 100%;
  }
}

.bg-dark {
  background-color: #000 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

.w50 {
  width: 50px !important;
}

.w60 {
  width: 60px !important;
}

.w80 {
  width: 80px !important;
}

.w100 {
  width: 100px !important;
}

.w150 {
  width: 150px !important;
}

.w174 {
  width: 174px !important;
}

.w200 {
  width: 200px !important;
}

.w300 {
  width: 300px !important;
}

.w400 {
  width: 400px !important;
}

.w-200 {
  width: 200% !important;
}

.w-max200 {
  max-width: 200px;
}

img {
  max-width: 100%;
}

h4.title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #278ea5;
  border-bottom: 1px solid #278ea5;
  padding-bottom: 5px;
  margin-bottom: 12px;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-7 {
  margin-top: 5rem;
}

.mt-8 {
  margin-top: 6rem;
}

.opacity-100 {
  opacity: 1;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-25 {
  opacity: 0.25;
}

.flex-1 {
  flex: 1;
}

.fs-14 {
  font-size: 14px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.color-square {
  width: 30px;
  height: 30px;
  border: 1px solid #aaa;
  padding: 0;
}

.table .thead-dark th,
.btn {
  white-space: nowrap;
}

.thead-dark th {
  color: #fff !important;
  background-color: #3b3e66 !important;
  border-color: #282a46;
}

.table_noData {
  height: 40vh;
  color: #fff;
  text-align: center;
  line-height: 40vh;
}

.btn.modal_btn {
  padding: 0.5rem 1.25rem !important;
}

.btn-close {
  width: 0.1em !important;
  height: 0.1em !important;
  margin-right: 0.5rem !important;
}

.ic-img-delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 30px;
  padding: 0;
  border: 0;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ic-img-delete:hover {
  background: rgba(0, 0, 0, 0.7);
}

.text-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  -webkit-line-clamp: 1;
  width: 100%;
}

.text-clamp.clamp2 {
  -webkit-line-clamp: 2;
}

.text-clamp.clamp3 {
  -webkit-line-clamp: 3;
}

/* .app-page-title {
  padding: 1rem 3rem;
} */
.breadcrumb {
  margin-bottom: 0;
  background: none;
}

@media (max-width: 767px) {
  .breadcrumb {
    justify-content: center;
  }
}

.breadcrumb a {
  color: #3b3e66;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.2s;
  font-size: 1rem;
  font-weight: normal;
}

.breadcrumb li {
  padding-left: 0.5rem;
  color: #d1d2db;
  font-size: 1rem;
  font-weight: normal;
}

/*required*/
.required {
  position: relative;
}

.required::before {
  content: '*';
  color: #d70000;
  margin-right: 5px;
  font-size: 20px;
  line-height: 0;
  position: absolute;
  left: -13px;
  top: 10px;
}

@media screen and (max-width: 767px) {
  .required::before {
    content: '';
    margin-right: 0;
  }

  .required::after {
    content: '*';
    color: #ce0000;
    font-size: 20px;
    line-height: 0;
    margin-left: 5px;
  }
}

/*form*/
.form-label-80 .form-group > label {
  width: 80px;
}

.form-label-100 .form-group > label {
  width: 100px;
}

.form-label-120 .form-group > label {
  width: 120px;
}

.form-label-140 .form-group > label {
  width: 140px;
}

.form-label-200 .form-group > label {
  width: 200px;
}

.form-label-250 .form-group > label {
  width: 250px;
}

.form-input-400 .form-group .form-control {
  width: 400px;
}

[class*='form-label-'] .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

[class*='form-label-'] .form-group > label {
  padding-top: 7px;
}

.form-list .form-item {
  flex: 1;
  padding: 0;
}

.form-list label {
  font-weight: 600;
  color: #000;
}

.form-error {
  color: red;
}

@media screen and (max-width: 767px) {
  [class*='form-label-'] .form-group {
    display: block;
    padding: 0;
    margin-bottom: 10px;
  }

  [class*='form-label-'] .form-group > label {
    display: block;
    width: 100%;
    text-align: left !important;
    padding-bottom: 0;
  }

  [class*='form-label-'] .form-group > label + input,
  [class*='form-label-'] .form-group > label + select,
  [class*='form-label-'] .form-group > label + textarea {
    margin-left: 0px;
  }

  [class*='form-label-'] .form-group .form-control {
    width: 100% !important;
  }

  .form-list > .row,
  .form-list > .row > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }

  .form-input-400 .form-group > .w400 {
    width: 100% !important;
  }

  .form-input-400 .form-group > .w400 > div {
    display: block !important;
  }

  .form-input-400 .form-group > .w400 .form-control {
    display: block;
    width: 100% !important;
  }
}

/*sidebar*/
.app-sidebar-logo--icon img {
  animation: none;
}

.app-sidebar-logo--icon {
  background: none !important;
  box-shadow: none !important;
  width: 50px;
}

/*modal*/

.modal-body,
.modal-header,
.modal-footer {
  padding: 1rem 2rem;
}

.modal-body {
  min-height: 150px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/*dropzone*/
.dropzone-box {
  display: flex;
  margin-top: 12px;
}

.dropzone-box .dropzone {
  margin-right: 24px;
  flex: 1 1;
  max-width: 200px;
}

.dropzone-box .dropzone-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f5fd !important;
  padding: 0.7rem !important;
  border: 2px dashed #ddd;
  border-radius: 15px;
  color: silver;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.dropzone-box .dropzone-inner-wrapper:hover {
  border: 2px dashed #2c3063;
}

.dropzone-icon {
  background-color: #fff;
  color: #3c44b1;
  width: 90px;
  height: 90px;
  line-height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin: 0.9rem auto;
  transition: all 0.3s ease-in-out;
}

/* .dropzone-icon:hover {
  transform: scale(1.2);
} */

.drag_active {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

.dropzone-upload-wrapper .dropzone-inner-wrapper {
  padding: 1rem;
}

.dropzone-box .alert-success {
  display: inline-block;
}

.card-footer {
  background-color: #fff !important;
  border-top: 0 solid rgba(122, 123, 151, 0.3) !important;
  position: relative;
  flex: 2;
}

.thumbs-img {
  max-width: 300px;
  /* display: inline-block; */
  padding: 0 !important;
  border-radius: 0.29rem;
  box-shadow: 0 0.18rem 0.3rem rgb(166 166 185 / 50%),
    0 0.326rem 3rem rgb(122 123 151 / 30%);
  border-color: #7a7b97 !important;
  margin-bottom: 1rem;
  top: 0 !important;
  left: 0 !important;
}

input[type='radio'],
input[type='checkbox'] {
  width: 20px;
  height: 20px;
  vertical-align: sub;
  align-self: center;
  margin: 2px;
}

/*radio button*/
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  width: 100px;
  border: 1px solid #ddd;
}

.inputGroup label {
  padding: 8px 15px 8px 45px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  margin-bottom: 0;
}

.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #5562eb;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.inputGroup label:after {
  width: 20px;
  height: 20px;
  content: '';
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: -3px -3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}

.inputGroup input:checked ~ label {
  color: #fff;
}

.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.inputGroup input:checked ~ label:after {
  background-color: #54e0c7;
  border-color: #54e0c7;
}

.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

/*searchBox*/
.searchBox .form-group {
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .searchBox .btn {
    width: 100%;
    margin-top: 10px;
  }

  .searchBox .form-group {
    padding: 0;
  }

  .searchBox .form-group,
  .searchBox .form-control {
    width: 100% !important;
    text-align: left;
    margin-left: 0 !important;
  }
}

/*--------------------------------------------------------------
# sort
--------------------------------------------------------------*/
/* table th.sortable {
  cursor: pointer;
} */
.sort-down {
  margin-left: -6px;
}

.no_sorting {
  opacity: 0.4 !important;
}

.drag_list {
  list-style: none;
  padding-left: 0px;
}

.drag_data {
  list-style: none;
  margin: 0.5rem 0rem 0.5rem 0;
  background-color: snow;
  border: lightgray solid 1px;
  padding: 0.3rem 0;
  border-radius: 8px;
}

.drag_data .title {
  padding: 0.5rem 1.5rem;
  background-color: rgb(10, 15, 53);
  color: #fff;
  border-radius: 8px 0 0 8px;
  font-size: 1.2rem;
}

.brand_name {
  padding: 0.4rem 0rem 0.4rem 1.5rem;
  border-radius: 0 8px 8px 0;
  font-weight: bold;
  font-size: 1.2rem;
}

/*btn-intro*/
.btn-intro {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.btn-intro .btn-wrapper--icon,
.ic-prd-dt {
  margin-right: 4px;
}

.ic-prd-dt {
  background: #212529;
  display: flex;
  padding: 3px;
  width: 24px;
  height: 16px;
}

.ic-prd-dt-img,
.ic-prd-dt-txt span {
  background: #fff;
}

.ic-prd-dt div:nth-child(1) {
  margin-right: 3px;
}

.ic-prd-dt-img {
  width: 50%;
}

.ic-prd-dt-txt {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ic-prd-dt-txt span {
  height: 2px;
  display: block;
  border-radius: 5px;
}

.ic-prd-dt-txt span:nth-child(1) {
  width: 100%;
}

.ic-prd-dt-txt span:nth-child(2) {
  width: 60%;
}

.ic-prd-dt-txt span:nth-child(3) {
  width: 80%;
}

.btn-intro-group .ic-prd-dt {
  background: #f4772e;
}

.btn-intro-group .ic-prd-dt-img,
.btn-intro-group .ic-prd-dt-txt span {
  background: rgb(253 235 224);
}

.btn-intro {
  padding: 0;
  display: flex;
  align-items: center;
}

.btn-intro .btn-wrapper--icon {
  color: #f4772e;
}

.btn-intro:hover .btn-wrapper--icon {
  color: #fdebe0;
}

.btn-intro .btn-wrapper--icon svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
}

.btn-intro:hover .ic-prd-dt {
  border-color: #fff;
}

.btn-intro:hover .ic-prd-dt-img,
.btn-intro:hover .ic-prd-dt-txt span {
  background: #fff;
}

/*btn*/
.btn-neutral-warning {
  background-color: rgba(244, 119, 46, 0.15) !important;
  color: #f4772e !important;
  transition: all 0.8s ease-in-out;
  padding: 0.375rem 0.375rem !important;
}

.btn-neutral-warning:hover {
  background-color: #f4772e !important;
  color: #fff !important;
  transform: translateY(-2px);
}

/* table th.sortable {
  cursor: pointer;
} */
/* .sort-up:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 1;
}
.sort-up:after {
  content: '\2193';
  opacity: 0.4;
}
.sort-down:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}
.sort-down:after {
  content: '\2193';
  opacity: 1;
} */

/*customes*/
.brand-logo-color .card-box {
  background-color: #000 !important;
}

/* pagination */
.rc-pagination {
  padding: 0;
  margin: 0;
  color: #fff;
}

.rc-pagination > li {
  list-style: none;
}

.rc-pagination-total-text {
  float: left;
  height: 36px;
  line-height: 36px;
  list-style: none;
  padding: 0;
  color: gray;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0.5rem 0 0;
}

.rc-pagination:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.rc-pagination-item {
  cursor: pointer;
  border-radius: 5px;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  list-style: none;
  float: left;
  border: 1px solid lightgray;
  background-color: #fff;
  margin-right: 0.5rem;
  transition: all 0.5s ease-in-out;
  outline: none;
  color: #3c44b1;
}

.rc-pagination-item a {
  text-decoration: none;
  color: gray;
}

.rc-pagination-prev:hover,
.rc-pagination-next:hover,
.rc-pagination-item:hover {
  border-color: gray;
}

.rc-pagination-item:hover a {
  color: #3c44b1;
}

.rc-pagination-item-disabled {
  cursor: not-allowed;
}

.rc-pagination-item-disabled:hover {
  border-color: gray !important;
}

.rc-pagination-item-disabled:hover a {
  color: lightgray !important;
}

.rc-pagination-item-active:hover,
.rc-pagination-item-active {
  background-color: #3c44b1;
  border-color: #3c44b1;
  box-shadow: 0 0.25rem 0.55rem rgb(60 68 177 / 35%);
  color: #fff;
}

.rc-pagination-item-active a,
.rc-pagination-item-active:hover a {
  color: #fff;
}

.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
  content: '•••';
  display: block;
  letter-spacing: 2px;
  font-size: 18px;
  color: lightgray;
  margin-top: 1px;
  border: 0 !important;
  background-color: transparent !important;
}

.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {
  color: #3c44b1;
}

.rc-pagination-jump-prev:hover:after {
  content: '«';
}

.rc-pagination-jump-next:hover:after {
  content: '»';
}

.rc-pagination-jump-prev-custom-icon,
.rc-pagination-jump-next-custom-icon {
  position: relative;
}

.rc-pagination-jump-prev-custom-icon:after,
.rc-pagination-jump-next-custom-icon:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transition: all 0.5s ease-in-out;
  content: '•••';
  opacity: 1;
  display: block;
  letter-spacing: 2px;
  color: gray;
  margin-top: 1px;
  background-color: transparent !important;
  border: 0 !important;
}

.rc-pagination-jump-prev-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-next {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.rc-pagination-jump-prev-custom-icon:hover:after,
.rc-pagination-jump-next-custom-icon:hover:after {
  opacity: 0;
  color: gray;
}

.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
  opacity: 1;
  color: #3c44b1;
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 0.5rem;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  cursor: pointer;
  color: #3c44b1;
  font-size: 18px;
  border-radius: 5px;
  list-style: none;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  float: left;
  text-align: center;
  outline: none;
}

.rc-pagination-prev button:after {
  content: '‹';
  display: block;
}

.rc-pagination-next button:after {
  content: '›';
  display: block;
}

.rc-pagination-prev,
.rc-pagination-next {
  border: 1px solid lightgray;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-pagination-prev button,
.rc-pagination-next button {
  color: #3c44b1;
  border: 0.5px solid transparent;
  background-color: transparent;
}

.rc-pagination-prev button:after,
.rc-pagination-next button:after {
  margin-top: -1px;
}

.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination-disabled button {
  color: gray;
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item,
.rc-pagination-disabled .rc-pagination-prev,
.rc-pagination-disabled .rc-pagination-next {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item:hover,
.rc-pagination-disabled .rc-pagination-prev:hover,
.rc-pagination-disabled .rc-pagination-next:hover {
  border-color: gray;
}

.rc-pagination-disabled .rc-pagination-item:hover button,
.rc-pagination-disabled .rc-pagination-prev:hover button,
.rc-pagination-disabled .rc-pagination-next:hover button {
  color: gray;
}

.rc-pagination-disabled .rc-pagination-jump-prev,
.rc-pagination-disabled .rc-pagination-jump-next {
  pointer-events: none;
}

.rc-pagination-options {
  float: left;
  margin-left: 15px;
}

.rc-pagination-options-size-changer {
  float: left;
  width: 120px;
}

.rc-pagination-options-quick-jumper {
  float: left;
  margin-left: 16px;
  height: 34px;
  line-height: 34px;
}

.rc-pagination-item-link {
  border: 0;
  background-color: transparent;
}

.rc-pagination-options-quick-jumper input {
  margin: 0 1rem;

  background-color: #3c44b1;
  border-radius: 5px;
  border: 1px solid gray;
  outline: none;
  padding: 3px 12px;
  width: 50px;
  height: 34px;
}

.rc-pagination-options-quick-jumper input:hover {
  border-color: #3c44b1;
}

.rc-pagination-options-quick-jumper button {
  display: inline-block;
  margin: 0 1rem;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  border-radius: 5px;
  height: 34px;
  user-select: none;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: gray;
  background-color: #fff;
  border-color: #e6e7f1;
}

.rc-pagination-options-quick-jumper button:hover,
.rc-pagination-options-quick-jumper button:active,
.rc-pagination-options-quick-jumper button:focus {
  color: #3c44b1;
  background-color: #3c44b1;
  border-color: #3c44b1;
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  border: none;
  height: 24px;
  line-height: 24px;
  margin: 0;
  font-size: 18px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  float: left;
  margin-right: 1rem;
  list-style: none;
}

.rc-pagination-simple .rc-pagination-simple-pager .rc-pagination-slash {
  margin: 0 10px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  margin: 0 1rem;

  background-color: #3c44b1;
  border-radius: 5px;
  border: 1px solid #3c44b1;
  outline: none;
  padding: 5px 1rem;
  min-height: 20px;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #3c44b1;
}

.rc-pagination-simple .rc-pagination-simple-pager button {
  display: inline-block;
  margin: 0 1rem;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 1rem;
  border-radius: 5px;
  height: 26px;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: gray;
  background-color: #3c44b1;
  border-color: #3c44b1;
}

.rc-pagination-simple .rc-pagination-simple-pager button:hover,
.rc-pagination-simple .rc-pagination-simple-pager button:active,
.rc-pagination-simple .rc-pagination-simple-pager button:focus {
  color: #3c44b1;
  background-color: #3c44b1;
  border-color: #3c44b1;
}

@media only screen and (max-width: 1024px) {
  .rc-pagination-item-after-jump-prev,
  .rc-pagination-item-before-jump-next {
    display: none;
  }
}

.swal2-container {
  z-index: 2000 !important;
}

/*label*/
.bss-top {
  padding: 2px 6px;
  border-radius: 4px;
  background: #dcdcdc;
  color: #fff;
  cursor: pointer;
}

.bss-top.active {
  background-image: linear-gradient(120deg, #9d41a8 0%, #f5576c 100%);
}

.channel_default {
  padding: 0.5rem 0.9rem;
  color: lightgray;
  border: 1px solid lightgrey;
  border-radius: 25px;
  display: block;
  margin: 0 auto;
  width: 65px;
  text-align: center;
  cursor: pointer;
}

.channel_default.active {
  background-color: rgb(56, 56, 56);
  color: white;
  border: 1px solid rgb(56, 56, 56);
}

.drag_dont_move {
  top: 0 !important;
  left: 0 !important;
}

/*datepicker*/
.react-datepicker__triangle::before {
  left: -50px !important;
}

.react-datepicker__triangle::after {
  left: -50px !important;
}

.react-datepicker-wrapper {
  position: relative;
}

.react-datepicker-wrapper::after {
  position: absolute;
  content: '';
  background-image: url('/public/images/calendar-days-solid.svg');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  right: 6px;
  top: 8px;
  pointer-events: none;
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
/*preview*/
.preview {
  font-family: 'Noto Sans TC', -apple-systemagenda-content-title,
    BlinkMacSystemFont, 'Segoe UI', 'Microsoft JhengHei', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  position: absolute;
  z-index: 999999;
  background-color: #fff;
  width: 100%;
  line-height: 1.6;
}

.preview_button_cancel {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  z-index: 9999999;
  box-shadow: 0 0.18rem 0.3rem rgba(35, 35, 36, 0.5);
  transition: all 0.5s ease-in-out;
}

.preview_button_cancel:hover {
  box-shadow: 0 0.18rem 0.3rem rgba(35, 35, 36, 0.5);
  transform: scale(0.95);
  transition: all 0.2s ease-in-out;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 0;
}

h1,
.h1,
.fs-xxl {
  font-size: var(--fs-xxl);
}

h2,
.h2,
.fs-xl {
  font-size: var(--fs-xl);
}

h3,
.h3,
.fs-lg {
  font-size: var(--fs-lg);
}

h4,
.h4,
.fs-md,
.btn-md {
  font-size: var(--fs-md);
}

@media (max-width: 1399px) {
  h4,
  .h4,
  .fs-md,
  .btn-md {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  h4,
  .h4,
  .fs-md,
  .btn-md {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  h4,
  .h4,
  .fs-md,
  .btn-md {
    font-size: var(--fs-s);
  }
}

h5,
.h5,
.fs-sm,
p {
  font-size: var(--fs-sm);
}

@media (max-width: 991px) {
  h5,
  .h5,
  .fs-sm,
  p,
  .m-small {
    font-size: 16px;
  }
}

h6,
.h6,
.fs-s {
  font-size: var(--fs-s);
}

h7,
.h7,
.small,
small,
.btn-xs {
  font-size: var(--fs-xs);
}

/*--------------------------------------------------------------
# layout
--------------------------------------------------------------*/
section,
.section {
  padding: 5.625rem 5rem;
}

.wrapBox {
  border-top: 1rem solid var(--lightBlue);
  width: calc(100vw - 8rem);
  margin: -5rem auto 0 auto;
  position: relative;
  z-index: 2;
}

.wrap,
.wrap-lg {
  margin-right: auto;
  margin-left: auto;
}

.wrap {
  max-width: 1200px !important;
}

.wrap-lg {
  max-width: 1400px;
}

@media (max-width: 1199px) {
  section,
  .section {
    padding: 4rem 3.5rem;
  }
}

@media (max-width: 767px) {
  section,
  .section {
    padding: 4em 0;
  }

  .wrap {
    padding: 2rem;
  }
}

@media (max-width: 575px) {
  .wrap {
    padding: 1.5rem;
  }
}

/*--------------------------------------------------------------
# hero
--------------------------------------------------------------*/
.hero {
  height: 100vh;
  padding-top: 3.75rem;
  background: #000;
  position: relative;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.about-hero h1 {
  opacity: 0.35;
  font-weight: 500;
  margin-bottom: 32px;
}

.about-hero .btn {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hero img,
.hero video {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: center;
}

.hero h1 {
}

.hero h4 {
  font-size: var(--fs-lg);
}

.no-hero {
  padding-top: 150px;
}

.hero-joinus h1 {
  white-space: nowrap;
}

.hero-joinus h1 span:first-child {
  margin-right: 2rem;
}

@media (max-width: 767px) {
  .hero h1 {
    letter-spacing: 1px;
    /*font-size: 9vw;*/
  }

  .hero h4 {
    letter-spacing: 1px;
    font-size: 5vw;
  }

  .hero {
    height: auto;
    padding-top: 0;
    width: 100%;
  }

  .hero img,
  .hero video {
    height: auto;
    position: relative;
  }

  .hero-joinus h1 {
    white-space: inherit;
  }

  .hero-joinus h1 span {
    white-space: nowrap;
  }

  .hero-joinus h1 span:first-child {
    margin-right: 0;
  }
}

/*-------------------------------------------------------------
# .pagination
--------------------------------------------------------------*/
.pagination {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 4rem;
}

.page-item.active .page-link {
  color: #4b4b4b;
}

.page-link {
  border: 0;
  color: #ffffff;
  background: none;
  font-size: var(--fs-md);
  display: flex;
  align-items: center;
  height: 100%;
}

.page-link:hover {
  color: #fff;
}

.page-item.active .page-link,
.page-link[aria-label],
.page-link:hover {
  background: none !important;
}

.page-item.active .page-link {
  color: #4b4b4b;
}

.pagination.dark .page-link {
  color: #606060;
}

.pagination.dark .arrow {
  border-color: #606060;
}

.arrow.tooltip-arrow {
  border-width: 0;
}

.pagination.dark .page-item.active .page-link,
.pagination.dark .page-link[aria-label],
.pagination.dark .page-link:hover {
  color: #000;
}

.pagechange {
  display: flex;
  font-size: var(--fs-xxs);
  color: #262626;
  justify-content: space-between;
  margin-top: 2.5rem;
  margin: 76px auto 0 auto;
  max-width: 400px;
}

.pagechange-prev,
.pagechange-next,
.pagechange-prev:hover,
.pagechange-next:hover {
  color: #262626;
}

.pagechange-prev span,
.pagechange-next span {
  border-color: #040000;
  padding: 17px;
}

.pagechange-prev.disabled span,
.pagechange-next.disabled span {
  border-color: #bbb;
}

.pagechange-prev.disabled,
.pagechange-next.disabled,
.pagechange-prev.disabled:hover,
.pagechange-next.disabled:hover {
  color: #bbb;
  cursor: default;
}

/*member*/
.mi-desktop {
  display: block;
}

.mi-mobile {
  display: none;
}

@media (max-width: 767px) {
  .mi-desktop {
    display: none;
  }

  .mi-mobile {
    display: block;
    margin: 0 auto;
  }
}

/*arrow*/
.arrow {
  border: solid white;
  border-width: 0px 2px 2px 0;
  display: inline-block;
  padding: 3px;
  align-self: center;
}

.arrow.right {
  transform: rotate(-45deg);
  color: #4b4b4b !important;
}

.arrow.left {
  transform: rotate(135deg);
}

/*--------------------------------------------------------------
# news
--------------------------------------------------------------*/
.news-item {
  border-radius: 0;
  border: 0;
  background: none;
  margin-bottom: 2rem;
}

.news-item p {
  font-size: var(--fs-s);
}

.index-news-list .news-item p {
  opacity: 0.21;
}

.news-bg {
  background-size: cover !important;
  background-position: center !important;
}

.newsDt {
  color: #000;
}

.newsDt .row {
  color: #262626;
  margin-bottom: 2.5rem;
  margin-top: 56px;
}

.newsDt h1 {
  line-height: 1.6;
  font-size: 2.125rem;
  text-align: center;
  /* font-weight: 900 !important; */
  margin-bottom: 2.5rem;
}

.newsDt .sub-title {
  line-height: 1.6;
  position: relative;
  font-size: 1.625rem;
  text-align: center;
  padding: 0px 1.75rem;
  display: inline-block;
  margin-top: 50px;
  font-weight: 600;
  margin-block: 2.5rem;
}

.newsDt .sub-title:before,
.newsDt .sub-title:after {
  content: ',,';
  position: absolute;
  font-size: 4.0625rem;
  font-weight: 200;
  font-family: 'IM Fell DW Pica', serif;
}

.newsDt .sub-title:before {
  left: 0;
  top: -43px;
  transform: rotate(180deg);
}

.newsDt .sub-title:after {
  right: 0;
  bottom: -41px;
}

.newDt-link {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 2.5rem;
}

.newDt-link li {
  margin-bottom: 18px;
}

.newDt-link a {
  color: var(--primary);
}

/**/
.index-news-list .news-list {
  justify-content: center;
}

@media (min-width: 768px) {
  .index-news-list a:first-child {
    padding-right: 4vw;
  }

  .index-news-list a:last-child {
    padding-left: 4vw;
  }
}

@media (max-width: 767px) {
  .news-list {
    flex-direction: column;
  }

  .news-item + .news-item {
    margin-top: 56px;
  }

  .newsDt h1 {
    font-size: var(--fs-md);
  }

  .newsDt .sub-title {
    font-size: var(--fs-sm);
  }

  .index-news-list .news-item p {
    opacity: 0.4;
  }
}

:root {
  --primary-dark: #071e3d;
  --primary-blue: #1f4287;
  --primary: #278ea5;
  --primary-light: #21e6c1;
  --gary-light: #9c9393;
  --gary-light2: #dcdddd;
  --gary-light3: #f9f9f9;
  --gary: #4c4c4c;
  --fs-xxl: 6rem;
  --fs-xl: 4.5rem;
  --fs-lg: 2rem;
  --fs-md: 1.5rem;
  --fs-sm: 1.125rem;
  --fs-s: 1rem;
  --fs-xs: 0.875rem;
  --fs-xxs: 0.75rem;
}

* {
  word-break: break-all;
}

/*--------------------------------------------------------------
# brand
--------------------------------------------------------------*/
/*brand-list*/
.brand-list {
  margin: 0;
  padding: 0;
  position: relative;
}

.brand-item {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.brand-bg {
  -webkit-filter: grayscale(1);
  z-index: 1;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  transition: 0.2s all ease;
  width: 100%;
}

.brand-bg-hover {
  position: absolute;
  z-index: 5;
  transition: 1s all ease;
  clip-path: circle(0px at center);
  width: 100%;
}

.brand-item:hover .brand-bg-hover {
  clip-path: circle(600px at center);
}

.brand-logo {
  z-index: 3;
  text-align: center;
  padding-top: 20%;
  width: 65%;
  z-index: 6;
}

.brand-logo,
.brand-logo img {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.brand-logo img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0));
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0));
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  transition: 0.2s all ease;
}

.brand-item:hover .brand-logo img {
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  transition: 0.2s all ease;
}

.brand-mask:before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.brand-item.empty {
  cursor: default;
}

.brand-item.empty .brand-mask:before {
  background-image: linear-gradient(80deg, #4b4a4b 0%, #262626 100%) !important;
}

.brand-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: max(1.87vw, var(--fs-sm));
  z-index: 3;
  white-space: nowrap;
}

@media (max-width: 767px) {
}

/*brand*/
.Carousel__ItemSet > div {
  display: flex;
  flex-direction: column;
}

.Carousel__ItemSet > div > a {
  width: 100%;
}

.brand-intro-wrap {
  background: #b2b0b5;
  position: relative;
  padding-bottom: 3rem;
}

.brand-intro {
  position: relative;
}

.brand-bg-front {
  position: absolute;
  fill: #564345;
}

.brand-content {
  width: 30%;
  float: right;
  margin-top: -2.5rem;
  z-index: 2;
  padding-right: 4vw;
}

.brand-img {
  padding-top: 30%;
  margin: 0 auto 2.5rem auto;
  position: relative;
  width: 100%;
}

.brand-content p {
  font-size: 1.2vw;
  height: 21vw;
  line-height: 2;
}

.brand-intro-imgB {
  width: 70%;
  float: left;
  padding-right: 10%;
  margin-top: -4vw;
  z-index: 2;
  position: relative;
}

.brand-intro-imgS {
  width: 30%;
  float: right;
}

.seller {
  margin-top: 74px;
  overflow: hidden;
  padding: 0 4rem;
}

.seller h4 {
  text-align: center;
  color: #606060;
  font-size: var(--fs-lg);
  font-weight: 900;
  margin-bottom: 1rem;
  position: relative;
}

.brand-intro-wrap.text-white .seller h4 {
  color: #fff;
}

.brand-intro-wrap.text-dark .seller h4 {
  color: var(--primary-dark);
}

.seller .slider-icon {
  width: 56px;
  font-size: 56px;
}

.seller .sliderBox {
  padding-left: 0;
  margin-left: 0;
}

.seller .slider-container {
  margin: 0;
  padding: 0 54px;
}

.brand-bg-m {
  display: none;
}

.seller .menu-hover a:before {
  height: 0;
}

@media (max-width: 1199px) {
  .seller {
    padding: 0 2rem;
  }

  .seller .slider-icon {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .brand-img {
    max-height: 5rem;
    margin: 0 auto 1.25rem auto;
  }
}

@media (max-width: 767px) {
  .seller h4 {
    font-size: var(--fs-sm);
  }

  .seller .slider-icon {
    display: none;
  }

  .brand-intro-imgS {
    order: 3;
    float: inherit;
    width: 60%;
    margin-left: 40%;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
  }

  .brand-intro-imgB {
    order: 1;
    float: inherit;
    width: 100%;
    margin-top: 0;
    padding-right: 0;
  }

  .brand-content {
    order: 2;
    float: inherit;
    width: 100%;
    text-align: center;
    position: relative;
    top: -3.5rem;
    padding: 0 1.5rem;
  }

  .brand-intro {
    padding-bottom: 56px;
  }

  .brand-bg-m {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 0 61vw 100vw;
    border-color: transparent transparent #b2b0b5 transparent;
    position: absolute;
    bottom: -1px;
    right: 0;
    display: block;
  }

  .brand-content p {
    text-align: left;
    font-size: var(--fs-s);
    height: auto;
  }

  .seller {
    margin-top: 1.5rem;
  }

  .brand-img {
    margin-bottom: 2rem;
  }

  .brand {
    padding-right: 0;
    padding-left: 0;
  }

  .brand title line,
  .brand p {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/*img*/
.object-cover {
  object-fit: cover;
}

.svg-mask {
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

.bg-cover {
  background-size: cover !important;
  background-position: center !important;
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------
# products
--------------------------------------------------------------*/
.products {
  padding: 60px 60px 0 60px;
}

.products section {
  padding: 32px;
}

.prd-submenu {
  display: flex;
  border-bottom: 1px solid #222;
  padding-bottom: 3rem;
  position: relative;
  margin-top: 1rem;
  align-items: center;
}

.prd-submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 1;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.prd-submenu li {
  padding: 0 20px;
}

.prd-submenu a {
  color: #000;
  flex: 1;
  display: inline-block;
  padding: 0 1rem;
  white-space: nowrap;
  font-size: var(--fs-md);
  cursor: pointer;
}

/*.prd-submenu .slider-container {
  margin-top: 20px;
}*/
.prd-submenu .slider-container .slider-icon {
  width: 24px;
}

.products select {
  width: 400px;
  margin-left: auto;
  height: 3.75rem;
}

.prdDt-name {
  margin-bottom: 1.5rem;
}

.prdDt-name .prd-name {
  height: auto;
  line-height: 1.4;
}

.prdDt-intro-item + .prdDt-intro-item {
  margin-top: 64px;
}

.prdDt-intro-txt {
  padding-left: 10vw !important;
  padding-right: 5rem !important;
  align-self: center;
}

.prdDt-intro-img + .prdDt-intro-txt {
  padding-right: 10vw;
  padding-left: 5rem;
}

.table-spec {
  border: 1px solid #fff;
  color: #fff;
}

.table-spec tr {
  border-bottom: 1px solid #fff;
}

.table-spec th {
  width: 30%;
  font-weight: normal;
}

.table-spec td {
  width: 70%;
}

.table-spec td,
.table-spec th {
  padding: 20px;
  border-right: 1px solid #fff;
  vertical-align: middle;
}

.prdDt-btm.text-dark .table-spec {
  color: #000;
  border: 1px solid #000;
}

.prdDt-btm.text-dark .table-spec tr,
.prdDt-btm.text-dark .table-spec td,
.prdDt-btm.text-dark .table-spec th {
  border-color: #000;
}

.prd-social-group {
  display: flex;
}

.prd-social-group .btn {
  color: #ededed !important;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prd-social-group svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  fill: #ededed;
}

.prd-social-group .btn:hover {
  color: #000000 !important;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.prd-social-group .btn:hover svg {
  fill: #000000;
}

.prd-social-group .btn svg g {
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.btn-buy {
  height: 96px;
  font-size: var(--fs-md) !important;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btn-buy:hover {
  color: black !important;
  background-color: transparent !important;
}

.hover_clear:hover {
  color: black !important;
  background-color: transparent !important;
}

.btn-buy img {
  margin-left: 0.75rem;
  height: 2.375rem;
}

.prd-buy-group {
  position: relative;
}

.prd-buy-group .btn {
  font-weight: 600;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

@media (max-width: 1400px) {
  .prdDt-intro-txt {
    padding-left: 2.5rem;
  }

  .prdDt-intro-img + .prdDt-intro-txt {
    padding-right: 2.5rem;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 767px) {
  .prdDt-intro-img + .prdDt-intro-txt {
    padding: 0 2rem;
  }

  .prdDt-intro-img {
    order: 1;
  }

  .prdDt-intro-txt h3 {
    margin-bottom: 1rem !important;
  }

  .img-group {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .img-group a {
    width: 42%;
  }

  .products {
    padding: 0px;
  }

  .products select {
    width: calc(100% - 64px);
    margin-left: 32px;
    height: 2.5rem;
  }

  .prdDt-name {
    margin-top: 1.5rem;
  }

  .prd-buy-group .btn {
    border: 0;
    background: rgba(196, 196, 196, 0.2);
    box-shadow: none;
  }

  .btn-buy {
    height: 3rem;
  }

  .btn-buy span {
    display: none;
  }

  .btn-buy img {
    margin-left: 0;
    height: 1.75rem;
  }

  .btn-invt .btn {
    height: 2rem;
    font-size: var(--fs-xs);
    padding: 0;
  }

  .prd-submenu {
    padding: 1.5rem 1rem 2rem 1rem;
    margin-top: 0;
  }

  .prd-submenu a {
    font-size: var(--fs-s);
  }

  .prd-submenu li {
    padding: 0 8px;
  }

  .products section {
    padding: 0px;
  }
}

/*search*/
.search {
  display: flex;
  /*  flex: 2 4 310px;*/
  min-width: 0;
  overflow: hidden;
}

.search input {
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  border-radius: 0;
  width: 300px;
  font-size: var(--fs-md) !important;
  width: max(18vw, 200px);
  font-weight: 100;
  text-align: start;
  font-style: italic;
}

.btn-search img {
  width: 34px;
}

.m-search {
  display: none;
}

.w-search {
  margin-left: 50px;
  height: 50px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .search input {
    font-size: var(--fs-sm) !important;
  }

  .w-search {
    display: none;
  }

  .m-search {
    display: flex;
    width: 100%;
    background: #fff;
    height: 60px;
    flex: 1 1 50px;
    position: relative;
  }

  .m-search .form-control {
    width: 100%;
    padding: 0 54px 0 36px;
  }

  .m-search .btn-close:before,
  .m-search .btn-close:after {
    background: #000;
  }

  .m-search .btn-close {
    position: absolute;
    right: 12px;
    top: 10px;
  }

  .m-search .btn-close:before,
  .m-search .btn-close:after {
    right: 3px;
    top: 23px;
  }
}

/*prdDt-modal*/
.prdDt-modal {
  top: 50% !important;
  transform: translateY(-50%) !important;
  margin: 0 auto;
  width: 600px;
  height: 600px;
  max-width: 600px;
}

.modal-content {
  height: 100%;
}

.invt-list {
  border-radius: 0.5rem;
  border: 1px solid #000;
  margin: 1rem;
  -webkit-box-shadow: 2px 2px 2px rgb(0 0 0 / 20%);
  box-shadow: 2px 2px 2px rgb(0 0 0 / 20%);
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  height: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
}

.invt .btn-close {
  box-shadow: none;
  background: #fff;
  position: absolute;
  right: 0;
  opacity: 1;
}

.invt .btn-close:before,
.invt .btn-close:after {
  background: #000;
  top: 23px;
  left: 6px;
}

.coupon-list {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coupon .modal-content {
  border: 0;
}

.coupon .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  z-index: 10;
  width: 100px;
}

.coupon .btn-close span {
  margin-right: 32px;
}

.coupon .btn-close:before,
.coupon .btn-close:after {
  width: 1.5rem;
  right: 20px;
}

@media (max-width: 1399px) {
  .prdDt-modal {
    width: 500px;
    height: 500px;
    max-width: 500px;
  }
}

@media (max-width: 575px) {
  .prdDt-modal {
    width: calc(100% - 10%);
    max-width: 100%;
  }

  .prdDt-modal.coupon {
    height: auto;
    height: auto;
  }
}

.mb-64 {
  margin-bottom: 4rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

/*youtube control*/
.frame-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  width: 300%; /* enlarge beyond browser width */
  left: -100%; /* center */
}

.frame-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  overflow: hidden;
  max-width: 100%;
}

.px-10vw {
  padding-left: 10vw !important;
  padding-right: 10vw !important;
}

.gary-light {
  color: var(--gary-light);
}

/*prd-item*/
.prd-item,
.prd-item:hover {
  color: #000;
  margin-bottom: 1.5rem;
}

.price {
  color: var(--primary);
  font-weight: 600;
  white-space: nowrap;
}

.price:before {
  content: 'NT$';
}

.prd-model {
  font-weight: 600;
}

.prd-name {
  height: 3.5rem;
}

@media (max-width: 767px) {
  .prd-name {
    height: 3rem;
  }
}

.slicker_dot ul {
  position: relative !important;
}

/*ul-list*/
.ul-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
}

.ul-list li {
  position: relative;
  color: #484343;
  padding: 4px 0 4px 30px;
}

.ul-list li:before {
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #484343;
  position: absolute;
  left: 7px;
  top: 16px;
}

.fw-bold {
  font-weight: bold;
}

.mb-2 {
  margin-bottom: 0.2rem;
}

@media (max-width: 767px) {
  .ul-list li {
    padding-left: 24px;
  }

  .ul-list li:before {
    width: 0.4rem;
    height: 0.4rem;
    top: 12px;
  }
}

@media (max-width: 767px) {
  .fs-lg,
  .h3,
  h3 {
    font-size: var(--fs-sm) !important;
  }
}

@media (max-width: 767px) {
  .fs-sm,
  .h5,
  .m-small,
  h5,
  p {
    font-size: var(--fs-xs);
  }
}

@media (max-width: 767px) {
  .btn-md,
  .fs-md,
  .h4,
  h4 {
    font-size: var(--fs-s);
  }
}

@media (max-width: 767px) {
  .btn-xs,
  .fs-s,
  .h6,
  .h7,
  .m-ssmall,
  .small,
  .ssmall,
  btn-xxs,
  h6,
  h7,
  small {
    font-size: var(--fs-xxs) !important;
  }
}

@media (max-width: 767px) {
  .prdDt-intro-img + .prdDt-intro-txt {
    padding: 0 2rem !important;
  }
}

@media (max-width: 575px) {
  .wrap {
    padding: 1.5rem !important;
  }
}

@media (max-width: 1400px) {
  .prdDt-intro-txt {
    padding-left: 2.5rem !important;
  }

  .left_img {
    padding-left: 5rem !important;
    padding-right: 2.5rem !important;
  }
}

@media (max-width: 767px) {
  .prdDt-intro-txt {
    padding: 0 2rem !important;
    -moz-box-ordinal-group: 1;
    order: 0;
  }
}

@media (max-width: 1399px) {
  .fs-lg,
  .h3,
  h3 {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  h3,
  .h3,
  .fs-lg {
    font-size: 22px;
  }
}

.react-datepicker-popper {
  /* padding: 10px !important; */
  z-index: 10 !important;
}

.select_tool {
  position: absolute;
  z-index: 3;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card {
  --bs-border-radius: 10px;
  --bs-card-inner-border-radius: 10px;
}

.min-width-100px {
  min-width: 100px;
}

.min-width-200px {
  min-width: 200px;
}

tr.bg > td {
  background: #bec0d8;
}

.dragging {
  background: palevioletred !important;
}
