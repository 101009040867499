* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.doPrint {
  width: 3cm;
  height: 1cm;
  padding: 0.2cm;
  margin: 0.5cm auto;
  border: 1px #000000 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 120%;
  text-align: center;
  cursor: pointer;
}

.page {
  width: 21cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #000000 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 237mm;
  outline: 2cm #ffeaea solid;
}

.printTable {
  border-width: 1px;
  border-spacing: 0px;
  border-collapse: separate;
  font-size: 95%;
  border-left: hidden;
  border-right: hidden;
  border-bottom: hidden;
  table-layout: fixed;
}

.printTable td {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #000;
  border-left: hidden;
  border-right: hidden;
  border-bottom: hidden;
  word-break: break-word;
}

.ColAlignRight {
  text-align: right;
}

.ColAlignLeft {
  text-align: left;
}

.ColAlignCenter {
  text-align: center;
}

.Col_12 {
  height: 10%;
  width: 8.33%;
  border: hidden;
}

.FontBold {
  font-weight: bold;
}

.FontNormal {
  font-weight: normal;
}

.HideBorderLeft {
  border-left: hidden;
}

.HideBorderRight {
  border-right: hidden;
}

@page {
  size: A4;
  margin: auto;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  #root > :not(.printWrap) {
    display: none;
  }
  .printWrap {
    overflow-y: visible !important;
    position: relative !important;
  }
  .doPrint {
    display: none;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    page-break-before: always;
    page-break-after: always;
  }
  h3 {
    font-size: 11pt !important;
  }
  .page-break {
    display: block;
    page-break-before: always;
    page-break-after: always;
  }
}
