.sideBar {
  width: 320px;
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  background-color: #253053;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  margin: 0;
}

.header {
  width: 320px;
  background-color: #253053;
  padding: 0 1.5rem;
  min-height: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgb(90, 91, 105);
  transition: all 1s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.logo_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_wrapper a {
  text-decoration: none;
  color: white;
  display: flex;
}

.logo_icon {
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  width: 40px;
  height: 40px;
}

.logo_icon img {
  width: 26px;
}

.logo_text {
  font-size: 1.28rem;
  font-weight: bold;
  color: white;
  text-transform: lowercase;
  line-height: 2;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.btn {
  border-radius: 5px;
  background-color: transparent;
  border: none;
  transition: all 0.5s ease-out;
  padding: 5px 7px;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
}

.navigation {
  background-color: #253053;
  width: 320px;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 85px 0;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s cubic-bezier(0.685, 0.0473, 0.346, 1);
  z-index: 90;
}

.navigation::-webkit-scrollbar {
  width: 0 !important;
}

.navigation li {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1px 0;
}

.navigation li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.navigation .navigation_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.2s;
  color: rgba(255, 255, 255, 0.45);
  padding: 0 24px;
  height: 44px;
  /* line-height: 44px; */
}

.navigation a:hover {
  color: #fff;
  transition: color 1s;
}

.navigationTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigationTitle > svg {
  font-size: 24px;
}

.navText {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 1rem;
}

.collapse {
  margin-top: 0;
  padding-left: 20px;
}

.arrow {
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform-origin: 40% 55%;
  margin-right: 5px;
}
