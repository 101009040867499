.header {
    width: 100%;
    min-height: 160px;
    background-color: #fff;
    padding: 5.7rem 3rem 1rem 23.2rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #2c3063;
    opacity: 0.999512;
    transform: scale(0.999995) translateZ(0px);
    transition: all 0.7s cubic-bezier(0.685, 0.0473, 0.346, 1);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.date-column {
    min-width: 170px !important;
}

@media (max-width: 767px) {
    .header {
        padding-left: 1.5rem !important;
    }
}

.breadcrumb {
    color: rgb(104, 104, 104);
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
}

.content {
    width: 100%;
    min-height: 78vh;
    padding: 3rem 3rem 7rem 23.2rem;
    transition: all 0.8s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media (max-width: 767px) {
    .content {
        padding: 1rem !important;
    }
}

.card {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0.46875rem 2.1875rem rgb(59 62 102 / 3%),
    0 0.9375rem 1.40625rem rgb(59 62 102 / 3%),
    0 0.25rem 0.53125rem rgb(59 62 102 / 5%),
    0 0.125rem 0.1875rem rgb(59 62 102 / 3%);
    border-radius: 0.65rem !important;
    padding: 3rem;
    overflow: hidden;
    font-family: 'Segoe UI';
}

@media (max-width: 767px) {
    .card {
        padding: 1rem;
    }
}

.card_header_info {
    background-color: #1b99a2 !important;
    color: #fff;
}

.text {
    text-align: right;
    margin: 0;
}

.table_head {
    color: #fff;
    background-color: #3b3e66;
    border-color: #282a46;
    cursor: default;
}

.table_head th {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0.8rem !important;
    cursor: default;
}

.table_body td {
    padding-left: 1rem;
    cursor: default;
}

.table_body input {
    cursor: pointer;
}

.w200 {
    min-width: 225px;
    width: 225px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.w100 {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal {
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

.modal_head {
    padding: 1rem 2rem !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color: #2c3063;
}

.modal_body {
    padding: 1rem 2rem !important;
}

.modal_footer {
    padding: 1rem 2rem !important;
    display: flex;
    justify-content: space-between !important;
}

.show_flex {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 1092px) {
    .show_flex {
        display: block;
        /* text-align: center; */
    }
}

.layout_flex {
    display: flex;
}

@media (max-width: 767px) {
    .layout_flex {
        display: block;
        /* text-align: center; */
    }
}

.formGroup {
    display: flex;
    text-align: left;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;
    font-family: 'Segoe UI';
}

.formGroup label {
    white-space: nowrap;
    margin-right: 1rem;
}

.formGroup select {
    margin: 1rem;
}

@media (max-width: 767px) {
    .formGroup {
        display: block;
    }
}

.space_1 {
    margin-right: 1rem;
}

.button_position {
    text-align: end;
}

@media (max-width: 767px) {
    .button_position {
        text-align: center;
    }
}

.form-keyword {
    width: 420px !important;
}

.form-select-long {
    width: 240px !important;
}

.form-select-multi-large {
    width: 360px !important;
}

.form-select {
    width: 200px !important;
    /* z-index: 99 !important; */
}

.form-select-repair {
    width: 371px !important;
}

.form-select-medium {
    width: 150px !important;
}

.form-select-short {
    width: 120px !important;
}

.form-input {
    width: 200px !important;
}

.form-input-short {
    width: 100px !important;
}

.form-input-order-number {
    width: 240px !important;
}

.form-input-email {
    width: 360px !important;
}

.rwd-w100 {
    width: 100px !important;
}

.rwd-w250 {
    width: 250px !important;
}

.rwd-w180 {
    width: 180px !important;
}

.rwd-w55 {
    width: 55px !important;
}

.form-table-header-sticky tr th {
    position: sticky;
    top: 0;
    border-style: none;
    z-index: 2;
}

.form-control-date-picker {
    z-index: 99 !important;
}

.seo-card-layout {
    padding: 3rem;
}

.padding tr td:first-child {
    padding: 1.5rem;
    white-space: nowrap;
}

.edit-account {
    width: 250px !important;
}

.select-poper-z-index {
    z-index: 9999 !important;
    position: relative;
}

.text-center {
    text-align: center;
}

.checkbox-with-label {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 1.5rem;
}

.first-name-input,
.last-name-input {
    width: 263px !important;
}

.large-input {
    width: 90% !important;
}

.serial-number-input {
    width: 360px !important;
}

.large-select {
    width: 100% !important;
}

.form-repair-detail {
    margin-left: 0.5rem;
}

.repair-button-orange {
    background: #ff962d;
    color: white !important;
    margin: 0.2rem;
}

.repair-modal-input {
    width: 437px !important;
}

.repair-record-card {
    width: 350px !important;
}

.repair-exchange-product {
    background-color: #ae0000;
    /* width: 250px; */
    margin-bottom: 1rem;
    color: white;
    display: block !important;
    /* text-align: center !important; */
}

.repair-change-records {
    background-color: #005757;
    /* width: 250px; */
    margin-bottom: 1rem;
    color: white;
    display: block !important;
    padding: 0.5rem;
    /* text-align: center !important; */
}

.warranty-card {
    margin-right: 2rem;
}

.warranty-full-screen-img {
    background-color: transparent !important;
    background: transparent !important;
}

.member-calculation {
    width: 260px;
    background-color: #343434;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.5rem;
    border-radius: 10px;
}

.invalid-date-border {
    border-color: red;
}

.form-select-shop-banner {
    width: 360px !important;
}

.form-textarea {
    width: 640px !important;
}

.form-select-brand-model {
    width: 180px !important;
}

.activityStatus {
    border-radius: 12px;
    color: white;
    font-size: 14px;
    padding: 0.2rem;
    text-align: center;
    width: 80px;
}

.radio-btn {
    width: 20px !important;
    height: 20px !important;
}

.mr-1p5 {
    margin-right: 1.5rem;
}

.radio-btn-layout {
    display: flex;
    justify-content: left;
    align-items: center;
}

.radio-btn-layout span {
    margin-left: 0.5rem;
}

.message-cat-card {
    width: 600px !important;
}

.message-cat-label {
    font-size: 20px;
    color: #2c3063;
    font-weight: bold;
}

@media (max-width: 768px) {
    .formGroup,
    .form-keyword,
    .form-input,
    .form-select,
    .form-select-medium,
    .form-select-short,
    .form-select-multi-large,
    .form-input-short,
    .form-input-order-number,
    .rwd-width-100,
    .checkbox-with-label,
    .first-name-input,
    .last-name-input,
    .large-input,
    .serial-number-input,
    .form-select-long,
    .form-select-repair,
    .form-repair-detail,
    .repair-modal-input,
    .repair-record-card,
    .repair-exchange-product,
    .repair-change-records,
    .warranty-card,
    .member-calculation,
    .form-input-email,
    .rwd-w100,
    .rwd-w250,
    .rwd-w180,
    .form-select-shop-banner,
    .form-select-brand-model,
    .message-cat-card,
    .form-textarea {
        width: 100% !important;
    }
}

.modal_button_submit {
    height: 40px;
    color: #fff;
    background-color: #3c44b1 !important;
    border-color: #3c44b1 !important;
    padding: 0.53rem 1.3rem !important;
    transition: all 0.2s ease-out !important;
    box-shadow: 3px 3px 6px #252e9f52;
    border: 1px solid #707070;
    border-radius: 3px;
}

.modal_button_submit:hover {
    transform: translateY(-2px) !important;
    background-color: #3c44b1 !important;
    border-color: #3c44b1 !important;
    box-shadow: 0 0.25rem 0.75rem rgb(60 68 177 / 55%) !important;
}

.modal_button_submit_extend {
    width: 135px;
}

.tab_button_large {
    width: 190px;
}

.modal_button_cancel {
    transition: all 0.2s ease-out;
    border-radius: 0.2rem;
    padding: 0.53rem 1.3rem !important;
    font-size: 1rem;
    transition: all 0.2s ease-out !important;
    background-color: #f4f5fd !important;
    color: #191d4d !important;
    border: 0 !important;
    margin-right: 1rem;
    text-decoration: none !important;
}

.modal_button_cancel:hover {
    transform: translateY(-2px) !important;
}

.upload_button {
    font-weight: bold !important;
    font-size: 0.9rem !important;
    background-color: #3c44b1 !important;
    padding: 4px 24px !important;
    color: #fff;
    width: 124px !important;
    height: 34px !important;
    border-radius: 17px !important;
    box-shadow: 0 0.25rem 0.55rem rgb(60 68 177 / 35%) !important;
    transition: all 0.2s ease-out !important;
}

.upload_button:hover {
    transform: scale(1.05);
    background-color: #3c44b1 !important;
}

.submit_button {
    width: 200px !important;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    border-radius: 0.2rem !important;
    color: #3b3e66 !important;
    background-color: #f8f9ff !important;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #f8f9ff !important;
    transition: all 0.2s ease-out !important;
    margin: 1rem 0;
}

.submit_button:hover {
    transform: translateY(-2px);
}

.search_card {
    box-shadow: 0 0.46875rem 2.1875rem rgb(59 62 102 / 3%),
    0 0.9375rem 1.40625rem rgb(59 62 102 / 3%),
    0 0.25rem 0.53125rem rgb(59 62 102 / 5%),
    0 0.125rem 0.1875rem rgb(59 62 102 / 3%);
    border-radius: 0.65rem !important;
    margin-bottom: 3rem;
}

.card_header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid lightgray !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_header_dark {
    color: #fff;
    border-bottom: 0 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card_body {
    padding: 1.25rem 3rem;
}

.sort_button {
    background: rgba(244, 119, 46, 0.15) !important;
    color: #f4772e !important;
    border-color: transparent;
    transition: all 0.2s ease-out;
    border-radius: 0.2rem;
    padding: 0.375rem 1.3rem !important;
    font-size: 1rem;
    float: right;
    margin-left: auto !important;
    border: 0 !important;
}

.sort_button:hover {
    background-color: #f4772e !important;
    color: #fff !important;
    border: 0;
    transform: translateY(-2px);
}

.discount-btn {
    border-radius: 26px !important;
    min-width: 115px;
    margin: 0.5rem;
}

@media (max-width: 767px) {
    .sort_button {
        text-align: center;
        float: none;
        width: 100%;
        margin-top: 1rem;
    }

    .card_body {
        padding: 1.25rem;
    }
}

.renew_panel {
    display: flex;
    align-items: center;
}

.renew_button {
    margin-left: 1rem;
}

.login_wrapper {
    background-color: #000 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1055;
}

.login_button {
    width: 50%;
    background-color: #000 !important;
    color: #fff;
    font-weight: 700 !important;
    padding: 0.5rem 1.5rem !important;
    text-align: start !important;
    transition: all 0.5s ease-in-out !important;
    box-shadow: 0 0.25rem 0.55rem rgb(7 9 25 / 35%);
}

.login_button:hover {
    transform: translateY(-2px);
    color: #fff;
    box-shadow: 0 0.25rem 0.55rem rgb(7 9 25 / 35%);
}

.button_intro {
    padding: 1rem 2rem;
    border: 0;
    color: #f4772e;
    background-color: rgba(244, 119, 46, 0.15);
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
}

.button_intro:hover {
    color: #fff;
    background-color: #f4772e;
    transform: translateY(-2px);
}

.table_overflow {
    overflow: auto;
}

.table_no_data {
    font-size: 24px;
    height: 100px;
    text-align: center;
}

@media (max-width: 767px) {
    .header {
        padding-top: 5.5rem;
        display: block;
        text-align: center;
    }

    .checkbox_item {
        width: 200% !important;
    }
}

.password_panel_title {
    font-size: 1.7rem;
    color: #3b3e66;
    margin-bottom: 1.5rem;
}

.password_panel_button {
    width: 50%;
    box-shadow: 0 0.25rem 0.55rem rgb(7 9 25 / 35%);
    transition: all 0.5s ease-out;
    border-radius: 0.2rem;
    font-weight: 700 !important;
    margin: 0.5rem !important;
    color: #fff !important;
    background-color: #070919 !important;
    border-color: #070919 !important;
}

.password_panel_button:hover {
    transition: all 0.5s ease-out;
    transform: scale(1.02);
}

.data_block {
    background-color: rgb(255, 215, 151);
    padding: 0.8rem 1.5rem;
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 1.25rem;
}

.data_block::before {
    position: absolute;
    content: '查看';
    top: 14px;
    right: 55px;
}

.data_block::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid black;
    top: 15px;
    right: -20px;
    padding: 0 1.5rem;
    cursor: pointer;
}

.payment_info_block {
    padding: 3rem;
    background-color: rgb(255, 234, 160);
    margin: 2rem 0;
}

.payment_info_block span {
    display: block;
}

.maintain_info span {
    display: block;
    margin-bottom: 1rem;
}

.maintain_item_remove_button {
    color: gray;
    cursor: pointer;
}

.maintain_item_group {
    display: flex;
    flex-wrap: wrap;
}

.maintain_item_remove_button:hover {
    color: #000;
}

.maintain_detail_container {
    position: relative;
    margin-bottom: 3rem;
}

.maintain_detail_container span {
    color: darkgray;
    white-space: pre-wrap;
}

.maintain_detail_remove_button {
    position: absolute;
    top: -12px;
    right: -55px;
}

.maintain_list_checkbox {
    width: 20px;
    height: 20px;
}

.repairItem_group {
    display: flex;
    flex-wrap: wrap;
}

.repairItem_group label {
    min-width: 2rem;
    margin-right: 0.5rem;
}

.checkbox_item_group {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
}

.checkbox_input {
    width: 15px;
    height: 15px;
    margin-right: 0.5rem;
}

.other_input {
    margin-left: 0.5rem;
    border: 1px solid #ced4da;
    box-shadow: none;
    border-radius: 0.25rem;
}

.other_input:focus {
    outline: none;
    border: 1px solid #ced4da;
    box-shadow: none;
    border-radius: 0.25rem;
}

.upload_button {
    display: none;
}

.upload_label {
    width: 125px;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 0.2rem 0.8rem 0.2rem 2.5rem;
    position: relative;
}

.maintain_item_label {
    flex: 0 1 150px !important;
}

.maintain_item_input {
    flex: 2 1 300px;
}

.fs_14 {
    font-size: 14px;
}

.bg_gray {
    background-color: #efefef !important;
}

.text_purple {
    color: #3c44b1 !important;
}

.text_dark_info {
    color: #0077ff !important;
}

.collapse_btn:hover {
    background-color: transparent !important;
    color: inherit !important;
}

/* to fixed layout */
.to_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.invalid {
    font-family: 'Segoe UI';
    font-size: 16px;
    color: #e20007;
    margin: 0;
    line-height: 16px;
}

.upsideDown {
    transition: all 0.6s;
    transform: rotate(-180deg);
}

.upsideDown-reverse {
    transition: all 0.6s;
    transform: rotate(0deg);
}

.link-text {
    color: #0077ff !important;
    cursor: pointer;
    text-align: center;
}

.message-record-font-style-staff {
    font-size: 20px;
    font-weight: bold;
}

.message-record-font-style-customer {
    font-size: 20px;
    font-weight: bold;
    color: #3C44B1;
}

.message-record-background-color-staff {
    background-color: #f2f2f2;
    border: none;
}

.message-record-background-color-customer {
    background-color: #e2e3f3;
    border: none;
}

.alert-style {
    padding: 1rem;
    border: none;
    border-radius: 10px;
    background-color: gray;
    margin: 1rem;
}

.newline-max-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 31px;
    white-space: pre-line;
}
