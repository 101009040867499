.footer {
  background: rgba(255, 255, 255, 0.9);
  height: 70px;
  width: 100%;
  text-align: center;
  line-height: 70px;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  font-size: 0.875rem;
  color: rgba(59, 62, 102, 0.5);
}

@media screen and (max-width: 912px) {
  .footer {
    padding-left: 0rem;
    justify-content: center;
  }
}
