.pip_preview {
  background-color: #dcdcdc;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.pip_preview svg {
  font-size: 40px;
  color: #ababab;
}
.pip_preview img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
}

.pick_img {
  width: 135px;
  height: 135px;
  object-fit: cover;
}
